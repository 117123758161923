import React from "react";
import "./index.scss";

const Block = ({ stepTitle, labelTitle, children }) => {
  return (
    <div className="PgSE-EstimatorBlock">
      <p className="PgSE-EstimatorBlock__step">{stepTitle}</p>
      <label className="PgSE-EstimatorBlock__label">{labelTitle}</label>
      {children}
    </div>
  );
};

export default Block;
