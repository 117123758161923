import React from "react";
import PropTypes from "prop-types";
import TrackedLink from "../../../Analytics/TrackedLink";
import SvgClose from "../../../../assets/images/vector/pages/sales-estimator/StepOne/offerPanelClose.svg";
import "./index.scss";

const OfferPanel = ({ handleOfferClose }) => {
  return (
    <div className="PgSE-EstimatorOfferPanel" onClick={handleOfferClose}>
      <div className="PgSE-EstimatorOfferPanel__directory">
        <p className="PgSE-EstimatorOfferPanel__title">
          High sales and low competition products are HERE
        </p>
        <TrackedLink
          category="active"
          action="Try now for FREE"
          class="PgSE-EstimatorOfferPanel__button"
          target
          path="/app/#/reports/insights"
        >
          Try now for FREE!
        </TrackedLink>
      </div>
      <div className="PgSE-EstimatorOfferPanel__wrapBtnClose">
        <div className="PgSE-EstimatorOfferPanel__btnClose">
          <img src={SvgClose} alt="X" />
        </div>
      </div>
    </div>
  );
};

OfferPanel.propTypes = {
  handleOfferClose: PropTypes.func,
};

export default OfferPanel;
