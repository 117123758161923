import React from "react";
import IDEAS_SCREENSHOT_CONTENT from "./index.content";
import Screen from "./Screen";
import "./index.scss";

const Screenshot = () => {
  const { SVG_PRODUCT_EXAMPLE, SVG_SCREEN_INFO, screenHistory, showerHead } =
    IDEAS_SCREENSHOT_CONTENT;
  return (
    <div className="PgSE-I-Screenshot">
      <img
        className="PgSE-I-Screenshot__productExample"
        src={SVG_PRODUCT_EXAMPLE}
        alt=""
      />
      <img
        className="PgSE-I-Screenshot__screenInfo"
        src={SVG_SCREEN_INFO}
        alt=""
      />
      <Screen
        screen={screenHistory}
        classes="PgSE-I-Screenshot__screenHistory"
      />
      <Screen screen={showerHead} classes="PgSE-I-Screenshot__showerHead" />
    </div>
  );
};

export default Screenshot;
