import Launchpad from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-amazon_launchpad.svg"
import Appliances from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-washing-machine.svg"
import Automotive from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-jeep.svg"
import Painting from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-painting.svg"
import Baby from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-baby.svg"
import Beauty from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-perfume.svg"
import Books from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-book.svg"
import Camera from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-photo-camera.svg"
import Phones from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-phone.svg"
import Clothing from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-shirt.svg"
import Collectible from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-collectible-coins.svg"
import Computers from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-computer.svg"
import Electronics from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-pendrive.svg"
import Everything from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-else.svg"
import Grocery from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-sale.svg"
import Health from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-health.svg"
import Home from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-house.svg"
import Kitchen from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-cithen.svg"
import Improvement from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-home_improvement.svg"
import Industrial from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-robot-arm.svg"
import Jewelry from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-diamond.svg"
import Kindle from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-tablet.svg"
import Dining from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-cutlery.svg"
import Musical from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-balalaika.svg"
import Office from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-folder.svg"
import Paid from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-paid_in_kindle_store.svg"
import Patio from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-acanthaceae.svg"
import Pet from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-cat.svg"
import Shoes from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-converse.svg"
import Software from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-web.svg"
import Sports from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-walk.svg"
import Tools from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-improvement.svg"
import Toys from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-baby-mobile.svg"
import Watches from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-wristwatch.svg"
import Games from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-game-console.svg"
import DVD from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-dvd.svg"
import KitchenHome from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-kitchen-home.svg"
import Lighting from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-lighting.svg"
import Luggage from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-laggage.svg"
import Music from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-music.svg"
import Care from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-health_personal_care.svg"
import HomeSimple from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-simple_home.svg"
import Bags from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-luggage_bags.svg"
import Baumarkt from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-baumarkt.svg"
import Haushalt from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-kuche_haushalt_wohnen.svg"
import Lebensmittel from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-lebensmittel_getranke.svg"
import Bricolage from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-bricol.svg"
import Informatique from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-informatique.svg"
import TV from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-tv.svg"
import Prima from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-kid-and-baby.svg"
import Cards from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-card.svg"
import Gourmet from "../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-grocery_gourmet_foods.svg"

import UsaFlag from "../../../assets/images/vector/pages/sales-estimator/StepOne/calculator-usa.svg"
import CanadaFlag from "../../../assets/images/vector/pages/sales-estimator/StepOne/calculator-canada.svg"
import FranceFlag from "../../../assets/images/vector/pages/sales-estimator/StepOne/calculator-france.svg"
import GBFlag from "../../../assets/images/vector/pages/sales-estimator/StepOne/calculator-gb.svg"
import GermanyFlag from "../../../assets/images/vector/pages/sales-estimator/StepOne/calculator-germany.svg"
import IndiaFlag from "../../../assets/images/vector/pages/sales-estimator/StepOne/calculator-india.svg"
import ItalyFlag from "../../../assets/images/vector/pages/sales-estimator/StepOne/calculator-italy.svg"
import MexicoFlag from "../../../assets/images/vector/pages/sales-estimator/StepOne/calculator-mexico.svg"
import SpainFlag from "../../../assets/images/vector/pages/sales-estimator/StepOne/calculator-spain.svg"

export const PG_SE_STEP_ONE_DATA_CONTENT = {
  COM: [
    { name: "Amazon Launchpad", img: Launchpad, id: "0" },
    { name: "Appliances", img: Appliances, id: "1" },
    { name: "Arts, Crafts & Sewing", img: Painting, id: "2" },
    { name: "Automotive", img: Automotive, id: "3" },
    { name: "Baby", img: Baby, id: "4" },
    { name: "Beauty & Personal Care", img: Beauty, id: "5" },
    { name: "Books", img: Books, id: "6" },
    { name: "Camera & Photo", img: Camera, id: "7" },
    { name: "Cell Phones & Accessories", img: Phones, id: "8" },
    { name: "Clothing, Shoes & Jewelry", img: Clothing, id: "9" },
    { name: "Collectible Coins", img: Collectible, id: "10" },
    { name: "Computers & Accessories", img: Computers, id: "11" },
    { name: "Electronics", img: Electronics, id: "12" },
    { name: "Everything Else", img: Everything, id: "13" },
    { name: "Grocery & Gourmet Food", img: Grocery, id: "14" },
    { name: "Health & Household", img: Health, id: "15" },
    { name: "Home & Kitchen", img: Kitchen, id: "16" },
    { name: "Home Improvement", img: Improvement, id: "17" },
    { name: "Industrial & Scientific", img: Industrial, id: "18" },
    { name: "Jewelry", img: Jewelry, id: "19" },
    { name: "Kindle Store", img: Kindle, id: "20" },
    { name: "Kitchen & Dining", img: Dining, id: "21" },
    { name: "Musical Instruments", img: Musical, id: "22" },
    { name: "Office Products", img: Office, id: "23" },
    { name: "Paid in Kindle Store", img: Paid, id: "24" },
    { name: "Patio, Lawn & Garden", img: Patio, id: "25" },
    { name: "Pet Supplies", img: Pet, id: "26" },
    { name: "Shoes", img: Shoes, id: "27" },
    { name: "Software", img: Software, id: "28" },
    { name: "Sports & Outdoors", img: Sports, id: "29" },
    { name: "Tools & Home Improvement", img: Tools, id: "30" },
    { name: "Toys & Games", img: Toys, id: "31" },
    { name: "Watches", img: Watches, id: "32" },
    { name: "Video Games", img: Games, id: "33" },
  ],
  CO_UK: [
    { name: "Baby", img: Baby, id: "0" },
    { name: "Beauty", img: Beauty, id: "1" },
    { name: "Car & Motorbike", img: Automotive, id: "2" },
    { name: "Clothing", img: Clothing, id: "3" },
    { name: "Computers", img: Computers, id: "4" },
    { name: "DIY & Tools", img: Tools, id: "5" },
    { name: "DVD & Blu-ray", img: DVD, id: "6" },
    { name: "Electronics", img: Electronics, id: "7" },
    { name: "Garden & Outdoors", img: Home, id: "8" },
    { name: "Grocery", img: Grocery, id: "9" },
    { name: "Health & Personal Care", img: Health, id: "10" },
    { name: "Home & Kitchen", img: Kitchen, id: "11" },
    { name: "Jewellery", img: Jewelry, id: "12" },
    { name: "Kitchen & Home", img: KitchenHome, id: "13" },
    { name: "Large Appliances", img: Appliances, id: "14" },
    { name: "Lighting", img: Lighting, id: "15" },
    { name: "Luggage", img: Luggage, id: "16" },
    { name: "Music", img: Music, id: "17" },
    { name: "Musical Instruments", img: Musical, id: "18" },
    { name: "Office Products", img: Office, id: "19" },
    { name: "PC & Video Games", img: Games, id: "20" },
    { name: "Pet Supplies", img: Pet, id: "21" },
    { name: "Shoes", img: Shoes, id: "22" },
    { name: "Software", img: Software, id: "23" },
    { name: "Sports & Outdoors", img: Sports, id: "24" },
    { name: "Stationery & Office Supplies", img: Office, id: "25" },
    { name: "Toys & Games", img: Toys, id: "26" },
    { name: "Watches", img: Watches, id: "27" },
  ],
  CA: [
    { name: "Automotive", img: Automotive, id: "0" },
    { name: "Baby", img: Baby, id: "1" },
    { name: "Beauty & Personal Care", img: Beauty, id: "2" },
    { name: "Books", img: Books, id: "3" },
    { name: "Clothing & Accessories", img: Clothing, id: "4" },
    { name: "Electronics", img: Electronics, id: "5" },
    { name: "Grocery & Gourmet Food", img: Grocery, id: "6" },
    { name: "Health & Personal Care", img: Care, id: "7" },
    { name: "Home", img: HomeSimple, id: "8" },
    { name: "Home & Kitchen", img: Kitchen, id: "9" },
    { name: "Industrial & Scientific", img: Industrial, id: "10" },
    { name: "Jewelry", img: Jewelry, id: "11" },
    { name: "Luggage & Bags", img: Bags, id: "12" },
    { name: "Music", img: Music, id: "13" },
    { name: "Musical Instruments, Stage & Studio", img: Games, id: "14" },
    { name: "Office Products", img: Office, id: "15" },
    { name: "Patio, Lawn & Garden", img: Patio, id: "16" },
    { name: "Pet Supplies", img: Pet, id: "17" },
    { name: "Shoes & Handbags", img: Shoes, id: "18" },
    { name: "Sports & Outdoors", img: Sports, id: "19" },
    { name: "Tools & Home Improvement", img: Tools, id: "20" },
    { name: "Toys & Games", img: Toys, id: "21" },
    { name: "Watches", img: Watches, id: "22" },
  ],
  DE: [
    { name: "Auto & Motorrad", img: Automotive, id: "0" },
    { name: "Baby", img: Baby, id: "1" },
    { name: "Baumarkt", img: Baumarkt, id: "2" },
    { name: "Beauty", img: Beauty, id: "3" },
    { name: "Bekleidung", img: Clothing, id: "4" },
    { name: "Beleuchtung", img: Lighting, id: "5" },
    { name: "Bücher", img: Books, id: "6" },
    { name: "Bürobedarf & Schreibwaren", img: Office, id: "7" },
    { name: "Computer & Zubehör", img: Computers, id: "8" },
    { name: "DVD & Blu-ray", img: DVD, id: "9" },
    { name: "Drogerie & Körperpflege", img: Health, id: "10" },
    { name: "Elektro-Großgeräte", img: Electronics, id: "11" },
    { name: "Elektronik & Foto", img: Electronics, id: "12" },
    { name: "Games", img: Games, id: "13" },
    { name: "Garten", img: Home, id: "14" },
    { name: "Gewerbe, Industrie & Wissenschaft", img: Industrial, id: "15" },
    { name: "Haustier", img: Pet, id: "16" },
    { name: "Kamera & Foto", img: Camera, id: "17" },
    { name: "Koffer, Rucksäcke & Taschen", img: Bags, id: "18" },
    { name: "Küche & Haushalt", img: Kitchen, id: "19" },
    { name: "Küche, Haushalt & Wohnen", img: Haushalt, id: "20" },
    { name: "Lebensmittel & Getränke", img: Lebensmittel, id: "21" },
    { name: "Musik-CDs & Vinyl", img: Musical, id: "22" },
    { name: "Musikinstrumente & DJ-Equipment", img: Musical, id: "23" },
    { name: "Schmuck", img: Jewelry, id: "24" },
    { name: "Schuhe & Handtaschen", img: Shoes, id: "25" },
    { name: "Software", img: Software, id: "26" },
    { name: "Spielzeug", img: Toys, id: "27" },
    { name: "Sport & Freizeit", img: Sports, id: "28" },
    { name: "Uhren", img: Watches, id: "29" },
  ],
  FR: [
    { name: "Animalerie", img: Pet, id: "0" },
    { name: "Auto et Moto", img: Automotive, id: "1" },
    { name: "Bagages", img: Luggage, id: "2" },
    { name: "Beauté et Parfum", img: Beauty, id: "3" },
    { name: "Bijoux", img: Jewelry, id: "4" },
    { name: "Bricolage", img: Bricolage, id: "5" },
    { name: "Bébés & Puériculture", img: Baby, id: "6" },
    { name: "Chaussures et Sacs", img: Shoes, id: "7" },
    { name: "Commerce, Industrie & Science", img: Industrial, id: "8" },
    { name: "Cuisine & Maison", img: Dining, id: "9" },
    { name: "DVD & Blu-ray", img: DVD, id: "10" },
    { name: "Epicerie", img: Grocery, id: "11" },
    { name: "Fournitures de bureau", img: Office, id: "12" },
    { name: "Gros électroménager", img: Electronics, id: "13" },
    { name: "High-Tech", img: Computers, id: "14" },
    { name: "Hygiène et Soins du corps", img: Health, id: "15" },
    { name: "Informatique", img: Informatique, id: "16" },
    { name: "Instruments de musique", img: Musical, id: "17" },
    { name: "Jardin", img: Patio, id: "18" },
    { name: "Jeux et Jouets", img: Toys, id: "19" },
    { name: "Jeux vidéo", img: Games, id: "20" },
    { name: "Livres anglais et étrangers", img: Books, id: "21" },
    { name: "Livres", img: Books, id: "22" },
    { name: "Logiciels", img: Software, id: "23" },
    { name: "Luminaires et Eclairage", img: Lighting, id: "24" },
    { name: "Montres", img: Watches, id: "25" },
    { name: "Sports et Loisirs", img: Sports, id: "26" },
    { name: "Vêtements et accessoires", img: Clothing, id: "27" },
  ],
  ES: [
    { name: "Bebé", img: Baby, id: "0" },
    { name: "Belleza", img: Beauty, id: "1" },
    { name: "Bricolaje y herramientas", img: Tools, id: "2" },
    { name: "Coche y moto", img: Automotive, id: "3" },
    { name: "Deportes y aire libre", img: Sports, id: "4" },
    { name: "Electrónica", img: Electronics, id: "5" },
    { name: "Equipaje", img: Luggage, id: "6" },
    { name: "Hogar y cocina", img: Kitchen, id: "7" },
    { name: "Iluminación", img: Lighting, id: "8" },
    { name: "Industria, empresas y ciencia", img: Industrial, id: "9" },
    { name: "Informática", img: Informatique, id: "10" },
    { name: "Instrumentos musicales", img: Musical, id: "11" },
    { name: "Jardín", img: Patio, id: "12" },
    { name: "Joyería", img: Jewelry, id: "13" },
    { name: "Juguetes y juegos", img: Toys, id: "14" },
    { name: "Libros", img: Books, id: "15" },
    { name: "Oficina y papelería", img: Office, id: "16" },
    { name: "Películas y TV", img: TV, id: "17" },
    { name: "Relojes", img: Watches, id: "18" },
    { name: "Ropa", img: Clothing, id: "19" },
    { name: "Salud y cuidado personal", img: Health, id: "20" },
    { name: "Software", img: Software, id: "21" },
    { name: "Videojuegos", img: Games, id: "22" },
    { name: "Zapatos y complementos", img: Shoes, id: "23" },
  ],
  IT: [
    { name: "Abbigliamento", img: Clothing, id: "0" },
    { name: "Alimentari e cura della casa", img: Home, id: "1" },
    { name: "Auto e Moto", img: Automotive, id: "2" },
    { name: "Bellezza", img: Beauty, id: "3" },
    { name: "Cancelleria e prodotti per ufficio", img: Office, id: "4" },
    { name: "Casa e cucina", img: Dining, id: "5" },
    { name: "Commercio, Industria e Scienza", img: Industrial, id: "6" },
    { name: "Elettronica", img: Electronics, id: "7" },
    { name: "Fai da te", img: Painting, id: "8" },
    { name: "Film e TV", img: TV, id: "9" },
    { name: "Giardino e giardinaggio", img: Patio, id: "10" },
    { name: "Giochi e giocattoli", img: Toys, id: "11" },
    { name: "Gioielli", img: Jewelry, id: "12" },
    { name: "Illuminazione", img: Lighting, id: "13" },
    { name: "Informatica", img: Informatique, id: "14" },
    { name: "Libri", img: Books, id: "15" },
    { name: "Orologi", img: Watches, id: "16" },
    { name: "Prima infanzia", img: Prima, id: "17" },
    { name: "Salute e cura della persona", img: Health, id: "18" },
    { name: "Scarpe e borse", img: Shoes, id: "19" },
    { name: "Software", img: Software, id: "20" },
    { name: "Sport e tempo libero", img: Sports, id: "21" },
    { name: "Strumenti musicali e DJ", img: Musical, id: "22" },
    { name: "Valigeria", img: Luggage, id: "23" },
    { name: "Videogiochi", img: Games, id: "24" },
  ],
  IN: [
    { name: "Baby", img: Baby, id: "0" },
    { name: "Bags, Wallets & Luggage", img: Bags, id: "1" },
    { name: "Beauty", img: Beauty, id: "2" },
    { name: "Books", img: Books, id: "3" },
    { name: "Car & Motorbike", img: Automotive, id: "4" },
    { name: "Clothing & Accessories", img: Clothing, id: "5" },
    { name: "Electronics", img: Electronics, id: "6" },
    { name: "Gift Cards", img: Cards, id: "7" },
    { name: "Grocery & Gourmet Foods", img: Gourmet, id: "8" },
    { name: "Health & Personal Care", img: Health, id: "9" },
    { name: "Home & Kitchen", img: Kitchen, id: "10" },
    { name: "Industrial & Scientific", img: Industrial, id: "11" },
    { name: "Jewellery", img: Jewelry, id: "12" },
    { name: "Movies & TV Shows", img: TV, id: "13" },
    { name: "Music", img: Music, id: "14" },
    { name: "Musical Instruments", img: Musical, id: "15" },
    { name: "Office Products", img: Office, id: "16" },
    { name: "Pet Supplies", img: Pet, id: "17" },
    { name: "Shoes & Handbags", img: Shoes, id: "18" },
    { name: "Software", img: Software, id: "19" },
    { name: "Sports, Fitness & Outdoors", img: Sports, id: "20" },
    { name: "Toys & Games", img: Toys, id: "21" },
    { name: "Video Games", img: Games, id: "22" },
    { name: "Watches", img: Watches, id: "23" },
  ],
  COM_MX: [
    { name: "Bebé", img: Baby, id: "0" },
    { name: "Deportes y Aire Libre", img: Sports, id: "1" },
    { name: "Electrónicos", img: Electronics, id: "2" },
    { name: "Herramientas y Mejoras del Hogar", img: Tools, id: "3" },
    { name: "Hogar y Cocina", img: Kitchen, id: "4" },
    { name: "Juegos y juguetes", img: Toys, id: "5" },
    { name: "Oficina y Papelería", img: Office, id: "6" },
    { name: "Ropa, Zapatos y Accesorios", img: Clothing, id: "7" },
    { name: "Salud, Belleza y Cuidado Personal", img: Health, id: "8" },
    { name: "Videojuegos", img: Games, id: "9" },
  ],
}

export const PG_SE_STEP_ONE_COUNTRIES_CONTENT = [
  { name: "USA", flag: UsaFlag, domain: "COM", defaultCategory: 16 },
  { name: "United Kingdom", flag: GBFlag, domain: "CO_UK", defaultCategory: 11 },
  { name: "Germany", flag: GermanyFlag, domain: "DE", defaultCategory: 19 },
  { name: "France", flag: FranceFlag, domain: "FR", defaultCategory: 9 },
  { name: "Italy", flag: ItalyFlag, domain: "IT", defaultCategory: 5 },
  { name: "Spain", flag: SpainFlag, domain: "ES", defaultCategory: 7 },
  { name: "Canada", flag: CanadaFlag, domain: "CA", defaultCategory: 9 },
  { name: "Mexico", flag: MexicoFlag, domain: "COM_MX", defaultCategory: 4 },
  { name: "India", flag: IndiaFlag, domain: "IN", defaultCategory: 10 },
]

export const PG_SE_TOOLTIPS_CONTENT = {
  SALES_PER_MONTH: "Estimated number of items sold in a month. Available in our AMZScout PRO Extension.",
  EST_REVENUE: "Estimated Monthly Revenue. Available in our AMZScout PRO Extension.",
  FBA_FEES: "The amount Amazon receives for handling one item if it’s sold through FBA. Available in our AMZScout PRO Extension.",
  NET_PROFIT: "The difference between the minimum price and the sum of costs and fees. Available in our AMZScout PRO Extension.",
}