import React from "react";
import QuestionPic from "../../../../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-quesition.svg";
import Button from "../../../UI/Button";
import "./index.scss";

const Info = ({ handleButton }) => {
  return (
    <div className="PgSE-E-C-E-I">
      <div className="PgSE-E-C-E-I-block">
        <Button handleButton={handleButton} disabled>
          CALCULATE SALES
        </Button>
        <div className="PgSE-E-C-E-I-text">
          <p className="PgSE-E-C-E-I__sales">Sales per Month</p>
          <img src={QuestionPic} alt="?" loading="lazy" />
        </div>
      </div>
      <p className="PgSE-E-C-E-I__limit">
        Limit has been reached, please try again tomorrow
      </p>
    </div>
  );
};

export default Info;
