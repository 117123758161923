import React from "react";
import PropTypes from "prop-types";
import SUGGEST_AUTH_TITLES from "./index.content";
import { concatUrlWithParams, getTrueClasses } from "../../../../../helpers";
import TrackedLink from "../../../../Analytics/TrackedLink";
import "./index.scss";

const SuggestAuth = ({ isNewUser }) => {
  const { titleForNewUser, titleForExistingUser } = SUGGEST_AUTH_TITLES;
  const classes = getTrueClasses(
    "PgSE-E-Continue-SA",
    isNewUser && "PgSE-E-Continue-SA_newUser"
  );
  const text = isNewUser ? titleForNewUser : titleForExistingUser;
  const path = concatUrlWithParams("/authorization", {
    software: "LANDING",
    state: "login",
    url: `${process.env.HOST_NAME}/sales-estimator`,
    r: "sales-estimator",
  });

  return (
    <div className={classes}>
      <p className="PgSE-E-Continue-SA__title">{text}</p>
      <div className="PgSE-E-Continue-SA__content">
        <TrackedLink
          category="LANDING|Estimator"
          action="Continue"
          class="PgSE-E-Continue-SA__button"
          path={path}
        >
          CONTINUE
        </TrackedLink>
        {isNewUser && (
          <p className="PgSE-E-Continue-SA__card">No credit card required</p>
        )}
      </div>
    </div>
  );
};
SuggestAuth.propTypes = {
  isNewUser: PropTypes.bool,
};
export default SuggestAuth;
