/* eslint no-use-before-define: 0 */
import track from "../../tracking/analytics";
import { trackGa } from "../../tracking/googleAnalytics";

class AnalyticsController {
  #category = "LANDING|Estimator";
  #actions = ["blocked", "calculate"];
  #software = "LANDING";
  #label = "button";

  #send(action, sendToRedash) {
    trackGa(action, {
      category: this.#category,
      label: this.#label,
      transport_type: "beacon",
    });
    sendToRedash &&
      track(this.#category, action, this.#label, null, this.#software);
  }

  sendEstimateErrorEvent() {
    return this.#send(this.#actions[0], true);
  }

  sendEstimateSuccessEvent() {
    return this.#send(this.#actions[1], true);
  }
}

export default AnalyticsController;
