import React from "react";
import OfferPanel from "../../components/sales-estimator/Estimator/OfferPanel";
import { trackGa } from "../../tracking/googleAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { estimatorActions } from "../../store/slice/estimator-slice";

const OfferPanelContainer = () => {
  const { showOffer } = useSelector((state) => state.estimator);
  if (!showOffer) return null;

  const dispatch = useDispatch();
  const handleOfferClose = (e) => {
    const redirectPath = "app/#/reports/insights";

    if (e.target.tagName === "IMG") {
      localStorage.setItem("SHOW_OFFER", "false");
      dispatch(estimatorActions.updateShowOffer(false));
    } else {
      trackGa("click", { category: "LANDING|Estimator", label: redirectPath });
      window.open(`${process.env.MAIN_HOST}/${redirectPath}`, "_blank").focus();
    }
  };
  return <OfferPanel handleOfferClose={handleOfferClose} />;
};

export default OfferPanelContainer;
