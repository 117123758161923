import React from "react";
import Info from "./Info";
import CtaPanel from "../../CtaPanel";
import "./index.scss";

const Error = ({ handleButton }) => {
  return (
    <div className="PgSE-E-C-E">
      <Info handleButton={handleButton} />
      <CtaPanel />
    </div>
  );
};

export default Error;
