import React from "react";
import { useSelector } from "react-redux";
import CTA_BANNER_CONTENT from "./index.content";
import { getTrueClasses } from "../../../../../../helpers";
import TrackedLink from "../../../../../Analytics/TrackedLink";
import "./index.scss";

const CtaBanner = () => {
  const { isLoggedUser } = useSelector((state) => state.estimator);
  const { loggedInUser, nonLoggedInUser } = CTA_BANNER_CONTENT;
  const text = isLoggedUser ? loggedInUser.text : nonLoggedInUser.text;
  const path = isLoggedUser ? loggedInUser.path : nonLoggedInUser.path;
  return (
    <div
      className={getTrueClasses(
        "PgSE-E-C-R-CB",
        isLoggedUser && "PgSE-E-C-R-CB_log"
      )}
    >
      <TrackedLink
        category="LANDING|Estimator"
        action={text}
        class="PgSE-E-C-R-CB__button"
        path={path}
      >
        {text}
      </TrackedLink>
      <p className="PgSE-E-C-R-CB__title">
        Get access to data on monthly revenue, net profit and many more.
      </p>
    </div>
  );
};

export default CtaBanner;
