import React from "react";
import Container from "../../common/Container";
import SimpleList from "../UI/SimpleList";
import Screenshot from "../UI/Screenshot";
import TrackedLink from "../../Analytics/TrackedLink";
import NICHE_CONTENT from "./index.content";
import "./index.scss";

const Niche = () => {
  const { LIST, SCREENSHOT } = NICHE_CONTENT;
  return (
    <section className="PgSE-N">
      <Container>
        <h2 className="PgSE-N__title">
          Outsource Your Product Research to Experts
        </h2>
        <p className="PgSE-N__description">
          Are you overwhelmed by the amount of time and effort it takes to
          estimate sales data and conduct product research?
        </p>
        <div className="PgSE-N-block">
          <Screenshot screenshot={SCREENSHOT} classes="PgSE-N__screenshot" />
          <div className="PgSE-N-info">
            <SimpleList
              classes="PgSE-N-List"
              title={LIST.title}
              items={LIST.items}
            />
            <TrackedLink
              category="LANDING|Estimator"
              action="LEARN MORE AND GET A SAMPLE"
              class="PgSE-N__link"
              target
              path="https://sellerhook.com/?utm_source=site&utm_medium=amzscout&utm_campaign=sales-estimator-text"
            >
              LEARN MORE AND GET A SAMPLE
            </TrackedLink>
          </div>
        </div>
        <TrackedLink
          category="LANDING|Estimator"
          action="LEARN MORE AND GET A SAMPLE"
          class="PgSE-N__link PgSE-N__link_mob"
          target
          path="https://sellerhook.com/?utm_source=site&utm_medium=amzscout&utm_campaign=sales-estimator-text"
        >
          LEARN MORE AND GET A SAMPLE
        </TrackedLink>
      </Container>
    </section>
  );
};

export default Niche;
