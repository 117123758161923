import React from "react";
import Container from "../Container";
import SimpleTrustPilot from "../UI/SimpleTrustPilot";
import "./index.scss";
import PG_SE_REVIEWS_CONTENT from "./index.content";
import SVGStars from "../../../assets/images/vector/components/common/review_stars.svg";

const RatingReviewLine = () => {
  return (
    <section className="RatingReviewLine">
      <Container mediumSize>
        <div className="RatingReviewLine-container">
          <SimpleTrustPilot />
          <div className="RatingReviewLine-reviews">
            {PG_SE_REVIEWS_CONTENT.reviews.map((item, index) => {
              return (
                <div
                  key={index}
                  className="RatingReviewLine-reviews-item RatingReviewLine-reviews__item"
                >
                  <img
                    className="RatingReviewLine-reviews-item__avatar"
                    src={item.pic}
                    alt="avatar"
                  />
                  <div className="RatingReviewLine-reviews-item__info">
                    <img src={SVGStars} alt="stars" />
                    <p>{item.name}</p>
                    <p>{item.text}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default RatingReviewLine;
