import React from "react";
import { PG_SE_TOOLTIPS_CONTENT } from "../../index.content";
import Button from "../../UI/Button";
import CtaBanner from "./CtaBanner";
import Question from "../../Question";
import { getTrueClasses } from "../../../../../helpers";
import { useSelector } from "react-redux";
import "./index.scss";

const Result = ({ handleButton }) => {
  const { result, fBAFees, netProfit, isError, isLoading, isValid } =
    useSelector((state) => state.estimator);
  const { SALES_PER_MONTH, EST_REVENUE, FBA_FEES, NET_PROFIT } =
    PG_SE_TOOLTIPS_CONTENT;
  const updatedResult = result.toLocaleString("en-US");

  return (
    <div
      className={getTrueClasses(
        "PgSE-E-C-R",
        result > 9999 && "PgSE-E-C-R_big"
      )}
      id="salesEstimator-behavior_data"
    >
      <div className="PgSE-E-C-R-content">
        <Button
          handleButton={handleButton}
          disabled={!isValid || isLoading || isError}
        >
          CALCULATE SALES
        </Button>
        <div className="PgSE-E-C-R__wrap">
          <div className="PgSE-E-C-R-block PgSE-E-C-R-block_sales">
            <div className="PgSE-E-C-R__title">Sales per Month</div>
            <div className="PgSE-E-C-R-data">
              <div className="PgSE-E-C-R-data__number">{updatedResult}</div>
              <Question tooltip={SALES_PER_MONTH} />
            </div>
          </div>
          <div className="PgSE-E-C-R-block PgSE-E-C-R-block_revenue">
            <div className="PgSE-E-C-R__title">Est. Revenue</div>
            <div className="PgSE-E-C-R-data">
              <div className="PgSE-E-C-R-data__number PgSE-E-C-R-data__number_blur">
                {`$${updatedResult}`}
              </div>
              <Question tooltip={EST_REVENUE} />
            </div>
          </div>
          <div className="PgSE-E-C-R-block PgSE-E-C-R-block_fees">
            <div className="PgSE-E-C-R__title">FBA fees</div>
            <div className="PgSE-E-C-R-data">
              <div className="PgSE-E-C-R-data__number PgSE-E-C-R-data__number_blur">
                {`$${fBAFees}`}
              </div>
              <Question tooltip={FBA_FEES} />
            </div>
          </div>
          <div className="PgSE-E-C-R-block PgSE-E-C-R-block_profit">
            <div className="PgSE-E-C-R__title">Net Profit</div>
            <div className="PgSE-E-C-R-data">
              <div className="PgSE-E-C-R-data__number PgSE-E-C-R-data__number_blur">
                {`$${netProfit}`}
              </div>
              <Question tooltip={NET_PROFIT} />
            </div>
          </div>
        </div>
      </div>
      <CtaBanner />
    </div>
  );
};

export default Result;
