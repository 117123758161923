import React from "react";
import PropTypes from "prop-types";

const Screenshot = ({ classes, screenshot }) => {
  const {
    WEBP,
    WEBP_2x,
    PNG,
    PNG_2x,
    WEBP_992,
    WEBP_992_2x,
    PNG_992,
    PNG_992_2x,
    WEBP_768,
    WEBP_768_2x,
    PNG_768,
    PNG_768_2x,
    WEBP_Mob,
    WEBP_Mob_2x,
    PNG_Mob,
    PNG_Mob_2x,
  } = screenshot;

  return (
    <picture>
      <source
        type="image/webp"
        media="(min-width: 1200px)"
        srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`}
      />
      <source media="(min-width: 1200px)" srcSet={`${PNG} 1x, ${PNG_2x} 2x`} />
      <source
        type="image/webp"
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={`${WEBP_992} 1x, ${WEBP_992_2x} 2x`}
      />
      <source
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={`${PNG_992} 1x, ${PNG_992_2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${WEBP_768} 1x, ${WEBP_768_2x} 2x`}
      />
      <source
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={`${PNG_768} 1x, ${PNG_768_2x} 2x`}
      />
      <source
        type="image/webp"
        media="(max-width: 767px)"
        srcSet={`${WEBP_Mob} 1x, ${WEBP_Mob_2x} 2x`}
      />
      <source
        media="(max-width: 767px)"
        srcSet={`${PNG_Mob} 1x, ${PNG_Mob_2x} 2x`}
      />
      <img src={PNG} className={classes} alt="img" loading="lazy" />
    </picture>
  );
};
Screenshot.propTypes = {
  screenshot: PropTypes.object.isRequired,
  classes: PropTypes.string,
};

export default Screenshot;
