import React, { useContext, useRef } from "react";
import Control from "../../components/sales-estimator/Estimator/Control";
import EstimatorController from "../../controllers/sales-estimator/estimatorController";
import { useDispatch, useSelector } from "react-redux";
import { estimatorActions } from "../../store/slice/estimator-slice";
import AnalyticsController from "../../controllers/sales-estimator/analyticsController";
import { LayoutContext } from "../../store/context/layout-context";

const analyticsController = new AnalyticsController();

const ControlContainer = () => {
  const context = useContext(LayoutContext);
  const countRef = useRef(context);
  countRef.current = context;

  const { country, category, rank, isValid, result } = useSelector(
    (state) => state.estimator
  );

  const {
    updateIsValid,
    plusCountSearch,
    updateIsError,
    updateResult,
    updateFBAFees,
    updateNetProfit,
    updateIsLoading,
    updateRank,
  } = estimatorActions;

  const dispatch = useDispatch();

  const handleCountSearch = () => {
    dispatch(plusCountSearch());
  };
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const getFBAFees = (result) => {
    const min = result < 10 ? 1 : 10;
    const max = result < 10 ? 10 : 100;
    return getRandomInt(min, max);
  };
  const getNetProfit = (result) => {
    const min = result < 10 ? 1 : 10 < result && result < 100 ? 10 : 100;
    const max = result < 10 ? 10 : 10 < result && result < 100 ? 100 : 1000;
    return getRandomInt(min, max);
  };

  const handleButton = async () => {
    const { captcha, token, isInjected, injection } = countRef.current;
    if (!isInjected) {
      injection(true);
    }
    const errorAction = () => {
      analyticsController.sendEstimateErrorEvent();
      dispatch(updateIsError(true));
      dispatch(updateResult(0));
      dispatch(updateFBAFees(0));
      dispatch(updateNetProfit(0));
      dispatch(updateIsValid(false));
      dispatch(updateRank(""));
    };

    if (isValid) {
      if (
        typeof window !== "undefined" &&
        !window.location.href.includes("localhost") &&
        !token
      ) {
        if (!captcha.current) {
          dispatch(updateIsLoading(true));
          return setTimeout(() => {
            handleButton();
          }, 500);
        } else {
          await captcha.current.executeAsync();
          dispatch(updateIsLoading(false));
        }
      }
      dispatch(updateIsLoading(true));
      const estimatorController = new EstimatorController(
        country.domain,
        rank,
        category.id
      );
      estimatorController
        .estimate()
        .then((sales) => {
          handleCountSearch();
          analyticsController.sendEstimateSuccessEvent();
          dispatch(updateResult(sales));
          if (sales !== result) {
            dispatch(updateFBAFees(getFBAFees(sales)));
            dispatch(updateNetProfit(getNetProfit(sales)));
          }
        })
        .catch(() => {
          errorAction();
        })
        .finally(() => dispatch(updateIsLoading(false)));
    }
  };

  return <Control handleButton={handleButton} />;
};

export default ControlContainer;
