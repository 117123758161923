import React from "react";
import Container from "../../common/Container";
import WORK_CONTENT from "./index.content";
import "./index.scss";

const Work = () => {
  return (
    <div className="PgSE-W">
      <Container>
        <h2 className="PgSE-W__title">How Does It Work?</h2>
        <div className="PgSE-W-steps">
          {WORK_CONTENT.map(({ mod, title, text }, i) => (
            <div key={i} className={`PgSE-W-step PgSE-W-step_${mod}`}>
              <p className="PgSE-W-step__number">{`0${i + 1}.`}</p>
              <p className="PgSE-W-step__title">{title}</p>
              {Array.isArray(text) ? (
                text.map((str, index) => (
                  <p key={`text${index}`} className="PgSE-W-step__text">
                    {str}
                  </p>
                ))
              ) : (
                <p className="PgSE-W-step__text">{text}</p>
              )}
            </div>
          ))}
        </div>
        <p className="PgSE-W__note">
          <b>Please note:</b> Monthly sales data numbers change regularly. For
          the most accurate picture of any product's sales, check its sales
          history. See below to learn more.
        </p>
      </Container>
    </div>
  );
};

export default Work;
