import React from "react";
import { getTrueClasses } from "../../../helpers";
import PropTypes from "prop-types";

const SimpleList = ({ classes, title, items, mod }) => (
  <div className={getTrueClasses(classes, mod && `${classes}_${mod}`)}>
    <h3 className={`${classes}__title`}>{title}</h3>
    <ul className={`${classes}-block`}>
      {items.map(({ template }, i) => (
        <li key={i} className={`${classes}-item`}>
          <div className={`${classes}__point`} />
          {template}
        </li>
      ))}
    </ul>
  </div>
);

SimpleList.propTyped = {
  classes: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.array.isRequired,
  mod: PropTypes.string,
};

export default SimpleList;
