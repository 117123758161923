import React from "react";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const Content = () => {
  return (
    <div className="PgSE-I__Content PgSE-I-Content">
      <h3 className="PgSE-I-Content__title">
        Are you looking for profitable product ideas?
      </h3>
      <p className="PgSE-I-Content__subTitle">
        Order an individual product research report, created specifically for
        you, and get winning product ideas
      </p>
      <div className="PgSE-I-Content-blockBtn">
        <p className="PgSE-I-Content__text">
          Top keywords and verified suppliers included in the report
        </p>
        <TrackedLink
          category="LANDING|Estimator"
          action="SEE DETAILS"
          class="PgSE-I-Content__btn"
          path="https://sellerhook.com/?utm_source=site&utm_medium=amzscout&utm_campaign=sales-estimator-banner"
          target
        >
          SEE DETAILS
        </TrackedLink>
      </div>
    </div>
  );
};

export default Content;
