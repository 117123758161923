import React from "react";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const CtaPanel = () => {
  return (
    <div className="PgSE-E-CP">
      <p className="PgSE-E-CP__title">
        <b>
          Get monthly reports about Amazon trends and a weekly list of hot
          products
        </b>{" "}
        in dozens of niches whose sales are growing explosively
      </p>
      <TrackedLink
        category="LANDING|Estimator"
        action="Get now for free"
        class="PgSE-E-CP__button"
        target
        path="/app/#/reports/reports"
      >
        Get now for free
      </TrackedLink>
    </div>
  );
};

export default CtaPanel;
