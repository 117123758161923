import React from "react";
import { useSelector } from "react-redux";
import Calculation from "./Calculation";
import Result from "./Result";
import Error from "./Error";

const Control = ({ handleButton }) => {
  const { isError, result } = useSelector((state) => state.estimator);
  return (
    <section className="PgSE-E-C">
      {!isError && !result ? <Calculation handleButton={handleButton} /> : null}
      {result && !isError ? <Result handleButton={handleButton} /> : null}
      {isError && !result ? <Error handleButton={handleButton} /> : null}
    </section>
  );
};

export default Control;
