import React from "react";
import SVGTrustPilot from "../../../../assets/images/vector/components/common/trustpilot_logo.svg";
import "./index.scss";

const SimpleTrustPilot = () => {
  return (
    <div className="SimpleTrustPilot">
      <img
        className="SimpleTrustPilot__icon"
        src={SVGTrustPilot}
        alt="Trust Pilot Image"
      />
      <div className="SimpleTrustPilot__title">
        <span>‘</span>Excellent<span>’</span>
      </div>
      <p className="SimpleTrustPilot__description">
        Based on Trustpilot reviews
      </p>
    </div>
  );
};

export default SimpleTrustPilot;
