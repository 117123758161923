import { concatUrlWithParams } from "../../../../../../helpers";

const CTA_BANNER_CONTENT = {
  loggedInUser: {
    text: "CHECK OUR TOOLS!", path: concatUrlWithParams("/app/#/database", {
      software: "LANDING",
      r: "sales-estimator"
    })
  },
  nonLoggedInUser: {
    text: "SIGN UP FOR A FREE TRIAL AND CHECK OUR TOOLS!",
    path: concatUrlWithParams("/authorization", {
      software: "LANDING",
      url: `${process.env.HOST_NAME}/sales-estimator`,
      r: "sales-estimator"
    })
  }
};

export default CTA_BANNER_CONTENT;