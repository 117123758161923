import React from "react";
import "./index.scss";
import Container from "../../common/Container";
import SVGHelpOne from "../../../assets/images/vector/pages/sales-estimator/Help/helpOne.svg";
import SVGHelpTwo from "../../../assets/images/vector/pages/sales-estimator/Help/helpTwo.svg";
import TrackedLink from "../../Analytics/TrackedLink";

const Help = () => {
  return (
    <section className="PgSE-Help">
      <Container>
        <p className="PgSE-Help__title">Do You Need More Help?</p>
        <div className="PgSE-Help-container">
          <div className="PgSE-Help__item PgSE-Help__item_first">
            <img src={SVGHelpOne} alt="" />
            <TrackedLink
              path="/blog/how-to-check-amazon-trends/"
              category="LANDING|Estimator"
              action="Check out our detailed step-by-step instruction"
              target
              class="PgSE-Help__link"
            >
              Check out our detailed step-by-step instruction
            </TrackedLink>
          </div>
          <div className="PgSE-Help__item PgSE-Help__item_second">
            <img src={SVGHelpTwo} alt="" />
            <TrackedLink
              path="/blog/how-to-find-a-niche-on-amazon-that-is-right-for/"
              category="LANDING|Estimator"
              action="Read an in-depth guide on how to use our product"
              target
              class="PgSE-Help__link"
            >
              Read an in-depth guide on how to use our product
            </TrackedLink>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Help;
