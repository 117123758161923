import React from "react";
import QuestionPic from "../../../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-quesition.svg";
import { useSelector } from "react-redux";
import Button from "../../UI/Button";
import "./index.scss";

const Calculation = ({ handleButton }) => {
  const { isError, isLoading, isValid } = useSelector(
    (state) => state.estimator
  );
  return (
    <div className="PgSE-E-C-C">
      <Button
        handleButton={handleButton}
        disabled={!isValid || isLoading || isError}
      >
        CALCULATE SALES
      </Button>
      <div className="PgSE-E-C-C-block">
        <p className="PgSE-E-C-C__sales">Sales per Month</p>
        <img src={QuestionPic} alt="" loading="lazy" />
      </div>
    </div>
  );
};

export default Calculation;
