import React from "react";
import "./index.scss";

const EmptySpace = ({ isShow, dealsSection }) => {
  if ((dealsSection === "true" && !isShow) || dealsSection === "false")
    return null;

  return <section className="PgSE-ES" />;
};

export default EmptySpace;
