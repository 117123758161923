import React, { useContext, useEffect, useRef } from "react";
import { LayoutContext } from "../../store/context/layout-context";
import { MediaAuthContext } from "../../store/context/media-auth-context";
import Estimator from "../../components/sales-estimator/Estimator";
import {
  PG_SE_STEP_ONE_COUNTRIES_CONTENT,
  PG_SE_STEP_ONE_DATA_CONTENT,
} from "../../components/sales-estimator/Estimator/index.content";
import { checkValue, getCookie } from "../../helpers";
import { useSelector, useDispatch } from "react-redux";
import { estimatorActions } from "../../store/slice/estimator-slice";

const EstimatorContainer = ({ getFormBlockState, setFormBlockState }) => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const context = useContext(LayoutContext);
  const countRef = useRef(context);
  countRef.current = context;
  const { country, category, rank, countSearch, isError } = useSelector(
    (state) => state.estimator
  );
  const {
    updateCountry,
    updateCategory,
    updateIsValid,
    plusCountSearch,
    updateShowOffer,
    toggleOpenedCountry,
    updateIsLoggedUser,
  } = estimatorActions;

  const dispatch = useDispatch();

  useEffect(() => {
    const isFullInfo =
      !!country.domain &&
      !!category.name &&
      !!checkValue("decimal", +rank, true);
    dispatch(updateIsValid(isFullInfo));
  }, [country, category, rank]);

  useEffect(() => {
    if (isError && countSearch && !getCookie("h")) {
      localStorage.setItem("SE_COUNT", JSON.stringify(countSearch));
      setFormBlockState(true);
    }
  }, [isError]);

  useEffect(() => {
    dispatch(updateCountry(PG_SE_STEP_ONE_COUNTRIES_CONTENT[0]));
    const domain = country.domain || PG_SE_STEP_ONE_COUNTRIES_CONTENT[0].domain;
    const defaultCategory =
      country.defaultCategory ||
      PG_SE_STEP_ONE_COUNTRIES_CONTENT[0].defaultCategory;
    dispatch(
      updateCategory(PG_SE_STEP_ONE_DATA_CONTENT[domain][defaultCategory])
    );
    dispatch(updateIsLoggedUser(isAuth));
    const localCount = localStorage.getItem("SE_COUNT");
    if (localCount) {
      dispatch(plusCountSearch(parseInt(localCount)));
    }
    defaultShowOffer();
    clickOutsideEvent();
  }, []);

  useEffect(() => {
    dispatch(updateIsLoggedUser(isAuth));
  }, [isAuth]);

  const defaultShowOffer = () => {
    const localShowOffer = localStorage.getItem("SHOW_OFFER");
    localShowOffer !== null &&
      dispatch(updateShowOffer(localShowOffer === "true"));
  };

  const clickOutsideEvent = () => {
    const layoutElement = document.getElementById("layout");
    const countryListElement = document.querySelector(".PgSE-EstimatorStepOne");
    const clickOutsideHandler = (e) =>
      !countryListElement?.contains(e.target) &&
      dispatch(toggleOpenedCountry(false));
    layoutElement.addEventListener("click", clickOutsideHandler);
  };
  return <Estimator getFormBlock={getFormBlockState} />;
};

export default EstimatorContainer;
