import React from "react";
import { PG_SE_STEP_ONE_DATA_CONTENT } from "../index.content";
import { useSelector } from "react-redux";
import ClosePic from "../../../../assets/images/vector/pages/sales-estimator/StepOne/estimator-close.svg";
import Block from "../Block";
import "./index.scss";

const StepTwo = ({ openCategoryModal, closeCategoryModal, selectCategory }) => {
  const { country, isOpenedCategory, category } = useSelector(
    (state) => state.estimator
  );
  return (
    <Block stepTitle="Step 2:" labelTitle="Select Category">
      <div className="PgSE-EstimatorStepTwo">
        <button className="PgSE-Estimator__radio" onClick={openCategoryModal}>
          <img src={category.img} alt="" loading="lazy" />
          {category.name}
        </button>
        {isOpenedCategory ? (
          <div
            className="modal fade bd-example-modal-lg"
            id="calculator_category"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="PgSE-Estimator__list PgSE-EstimatorStepTwo__list">
                    <p>Category:</p>
                    <button
                      className="btn_clear close"
                      onClick={closeCategoryModal}
                    >
                      <img src={ClosePic} alt="" loading="lazy" />
                    </button>
                    <ul id="salesEstimator-category">
                      {PG_SE_STEP_ONE_DATA_CONTENT[country.domain].map(
                        ({ id, img, name }) => (
                          <li
                            key={id}
                            onClick={() => selectCategory({ id, img, name })}
                          >
                            <img src={img} alt="" loading="lazy" />
                            {name}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Block>
  );
};

export default StepTwo;
