import React from "react";
import { getTrueClasses } from "../../../../../helpers";
import "./index.scss";

const Button = ({ children, handleButton, disabled, customClasses }) => (
  <button
    className={getTrueClasses("PgSE-E-UI-B", customClasses)}
    disabled={disabled}
    onClick={handleButton}
  >
    {children}
  </button>
);

export default Button;
