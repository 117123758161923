import React from "react";
import Screenshot from "./Screenshot";
import Content from "./Content";
import "./index.scss";

const Ideas = () => {
  return (
    <section className="PgSE-I">
      <div className="container">
        <div className="PgSE-I__board">
          <Screenshot />
          <Content />
        </div>
      </div>
    </section>
  );
};

export default Ideas;
