import React from "react";
import { useDispatch } from "react-redux";
import { estimatorActions } from "../../store/slice/estimator-slice";
import StepTwo from "../../components/sales-estimator/Estimator/StepTwo";

const StepTwoContainer = () => {
  const dispatch = useDispatch();
  const { toggleOpenedCategory, updateCategory } = estimatorActions;
  const openCategoryModal = () => {
    dispatch(toggleOpenedCategory(true));
    setTimeout(() => {
      const modal = document.getElementById("calculator_category");
      modal.classList.add("show");
      modal.classList.add("opened");
      document.getElementsByTagName("HTML")[0].style.overflow = "hidden";
    }, 0);
  };
  const closeCategoryModal = () => {
    const modal = document.getElementById("calculator_category");
    modal.classList.remove("show");
    modal.classList.remove("opened");
    setTimeout(() => {
      dispatch(toggleOpenedCategory(false));
      document.getElementsByTagName("HTML")[0].style.overflow = "auto";
    }, 100);
  };
  const selectCategory = (newCategory) => {
    dispatch(updateCategory(newCategory));
    closeCategoryModal();
  };

  return (
    <StepTwo
      openCategoryModal={openCategoryModal}
      closeCategoryModal={closeCategoryModal}
      selectCategory={selectCategory}
    />
  );
};

export default StepTwoContainer;
