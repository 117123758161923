import React from "react";
import { PG_SE_STEP_ONE_COUNTRIES_CONTENT } from "../index.content";
import Block from "../Block";
import "./index.scss";
import { useSelector } from "react-redux";

const StepOne = ({ setIsOpenedCountry, selectCountry }) => {
  const { country, isOpenedCountry } = useSelector((state) => state.estimator);
  return (
    <Block stepTitle="Step 1:" labelTitle="Select Country">
      <div className="PgSE-EstimatorStepOne">
        <button
          className="PgSE-Estimator__radio"
          onClick={() => setIsOpenedCountry(!isOpenedCountry)}
        >
          <img src={country.flag} alt="" />
          {country.name}
        </button>
        {isOpenedCountry ? (
          <div className="PgSE-Estimator__list PgSE-EstimatorStepOne__list">
            <ul id="salesEstimator-marketplace">
              {PG_SE_STEP_ONE_COUNTRIES_CONTENT.sort((a) =>
                a.name === country.name ? -1 : 0
              ).map((country, index) => (
                <li
                  className={`${
                    index === 0
                      ? "PgSE-Estimator__radio opened"
                      : "PgSE-Estimator__radio hidden"
                  }`}
                  key={country.name}
                  onClick={() => selectCountry(country)}
                >
                  <img src={country.flag} alt={country.name} loading="lazy" />
                  {country.name}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </Block>
  );
};

export default StepOne;
