import React from "react";
import PropTypes from "prop-types";

const Screen = ({ screen, classes }) => {
  const { WEBP, WEBP_2x, JPEG, JPEG_2x } = screen;

  return (
    <picture>
      <source type="image/webp" srcSet={`${WEBP} 1x, ${WEBP_2x} 2x`} />
      <source srcSet={`${JPEG} 1x, ${JPEG_2x} 2x`} />
      <img src={JPEG} className={classes ?? ""} alt="img" loading="lazy" />
    </picture>
  );
};
Screen.propTypes = {
  screen: PropTypes.object,
  classes: PropTypes.string,
};

export default Screen;
