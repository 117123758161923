import React from "react";
import SuggestAuth from "./SuggestAuth";
import CtaPanel from "../CtaPanel";
import "./index.scss";

const Continue = ({ isNewUser }) => {
  return (
    <section className="PgSE-E-Continue">
      <SuggestAuth isNewUser={isNewUser} />
      <CtaPanel />
    </section>
  );
};

export default Continue;
