import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const Question = ({ tooltip, view }) => {
  if (!tooltip) {
    return null;
  }
  const classes = getTrueClasses(
    "PgSE-E-Q__tooltip",
    view === "right" && "PgSE-E-Q__tooltip_right",
    view === "top" && "PgSE-E-Q__tooltip_top"
  );
  return (
    <div className="PgSE-E-Q">
      <div className={classes}>{tooltip}</div>
    </div>
  );
};

Question.propTypes = {
  view: PropTypes.string,
  tooltip: PropTypes.string,
};

export default Question;
