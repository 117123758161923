import React, { useContext, useRef } from "react";
import StepThree from "../../components/sales-estimator/Estimator/StepThree";
import { LayoutContext } from "../../store/context/layout-context";
import { useDispatch } from "react-redux";
import { estimatorActions } from "../../store/slice/estimator-slice";

const StepThreeContainer = () => {
  const context = useContext(LayoutContext);
  const countRef = useRef(context);
  const dispatch = useDispatch();
  countRef.current = context;

  const handleKeyUp = (e) => {
    const { injection, isInjected } = countRef.current;

    if (e.target.value.length > 0 && !isInjected) {
      injection(true);
    }
  };
  const isValidRank = (str) => {
    const pattern = /^(0|[1-9]\d{0,8})?$/;
    return pattern.test(str);
  };
  const setRank = (val) => {
    isValidRank(val) && dispatch(estimatorActions.updateRank(val));
  };
  return <StepThree setRank={setRank} handleKeyUp={handleKeyUp} />;
};

export default StepThreeContainer;
