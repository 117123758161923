import React from "react";
import { useDispatch } from "react-redux";
import { estimatorActions } from "../../store/slice/estimator-slice";
import StepOne from "../../components/sales-estimator/Estimator/StepOne";
import {
  PG_SE_STEP_ONE_COUNTRIES_CONTENT,
  PG_SE_STEP_ONE_DATA_CONTENT,
} from "../../components/sales-estimator/Estimator/index.content";

const StepOneContainer = () => {
  const dispatch = useDispatch();
  const { updateCountry, toggleOpenedCountry, updateCategory } =
    estimatorActions;

  const setIsOpenedCountry = (val) => {
    dispatch(toggleOpenedCountry(val));
  };

  const selectCountry = (newCountry) => {
    dispatch(updateCountry(newCountry));
    dispatch(toggleOpenedCountry(false));
    const { domain, defaultCategory } =
      newCountry || PG_SE_STEP_ONE_COUNTRIES_CONTENT[0];
    dispatch(
      updateCategory(PG_SE_STEP_ONE_DATA_CONTENT[domain][defaultCategory])
    );
  };

  return (
    <StepOne
      setIsOpenedCountry={setIsOpenedCountry}
      selectCountry={selectCountry}
    />
  );
};

export default StepOneContainer;
