class EstimatorModel {
  constructor() {
    this.requestHeaders = {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
    };
    this.domain = null;
    this.rank = null;
    this.categoryId = null;
  }
  request() {
    return fetch(
      process.env.API_HOST +
        "/v2/landing/sales?domain=" +
        this.domain +
        "&categoryId=" +
        encodeURIComponent(this.categoryId) +
        "&rank=" +
        this.rank,
      {
        headers: this.requestHeaders,
        method: "GET",
      }
    );
  }
}

export default EstimatorModel;
