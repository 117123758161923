import React from "react";
import PropTypes from "prop-types";
import SVGBlackClose from "../../../../assets/images/vector/components/common/blackClose.svg";
import "./index.scss";

const Close = ({ hideCloseButton }) => {
  if (hideCloseButton) return null;

  return (
    <button className="PgSE-DSE__close btn_clear PgSE-DSE__closeJS">
      <img
        className="PgSE-DSE__closeJS"
        id="js-close-panel"
        src={SVGBlackClose}
        alt="X"
        loading="lazy"
      />
    </button>
  );
};
Close.propTypes = {
  hideCloseButton: PropTypes.bool,
};
export default Close;
