import React from "react";
import SVG_PRODUCT_EXAMPLE from "../../../../assets/images/vector/pages/amzscout-vs-helium-10/Ideas/product_example.svg"
import SVG_SCREEN_INFO from "../../../../assets/images/vector/pages/amzscout-vs-helium-10/Ideas/screen_info.svg"

import JPEG_SCREEN_HISTORY from "../../../../assets/images/raster/pages/amzscout-vs-helium-10/Ideas/screen_history.jpg"
import JPEG_2x_SCREEN_HISTORY from "../../../../assets/images/raster/pages/amzscout-vs-helium-10/Ideas/screen_history@2x.jpg"
import WEBP_SCREEN_HISTORY from "../../../../assets/images/raster/pages/amzscout-vs-helium-10/Ideas/screen_history.webp"
import WEBP_2x_SCREEN_HISTORY from "../../../../assets/images/raster/pages/amzscout-vs-helium-10/Ideas/screen_history@2x.webp"

import PNG_SHOWER_HEAD from "../../../../assets/images/raster/pages/amzscout-vs-helium-10/Ideas/showerHead.png"
import PNG_2x_SHOWER_HEAD from "../../../../assets/images/raster/pages/amzscout-vs-helium-10/Ideas/showerHead@2x.png"
import WEBP_SHOWER_HEAD from "../../../../assets/images/raster/pages/amzscout-vs-helium-10/Ideas/showerHead.webp"
import WEBP_2x_SHOWER_HEAD from "../../../../assets/images/raster/pages/amzscout-vs-helium-10/Ideas/showerHead@2x.webp"

const IDEAS_SCREENSHOT_CONTENT = {
  SVG_PRODUCT_EXAMPLE,
  SVG_SCREEN_INFO,
  screenHistory: {
    JPEG: JPEG_SCREEN_HISTORY,
    JPEG_2x: JPEG_2x_SCREEN_HISTORY,
    WEBP: WEBP_SCREEN_HISTORY,
    WEBP_2x: WEBP_2x_SCREEN_HISTORY,
  },
  showerHead: {
    JPEG: PNG_SHOWER_HEAD,
    JPEG_2x: PNG_2x_SHOWER_HEAD,
    WEBP: WEBP_SHOWER_HEAD,
    WEBP_2x: WEBP_2x_SHOWER_HEAD,
  },
}
export default IDEAS_SCREENSHOT_CONTENT
