import React from "react";
import WEBP from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource.webp";
import WEBP_2x from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@2x.webp";
import PNG from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource.png";
import PNG_2x from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@2x.png";
import WEBP_992 from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@992.webp";
import WEBP_992_2x from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@992_2x.webp";
import PNG_992 from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@992.png";
import PNG_992_2x from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@992_2x.png";
import WEBP_768 from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@768.webp";
import WEBP_768_2x from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@768_2x.webp";
import PNG_768 from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@768.png";
import PNG_768_2x from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@768_2x.png";
import WEBP_Mob from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@mob.webp";
import WEBP_Mob_2x from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@mob_2x.webp";
import PNG_Mob from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@mob.png";
import PNG_Mob_2x from "../../../assets/images/raster/pages/sales-estimator/Niche/outsource@mob_2x.png";
import TrackedLink from "../../Analytics/TrackedLink";

const TRENDS_CONTENT = {
  SCREENSHOT: {
    WEBP,
    WEBP_2x,
    PNG,
    PNG_2x,
    WEBP_992,
    WEBP_992_2x,
    PNG_992,
    PNG_992_2x,
    WEBP_768,
    WEBP_768_2x,
    PNG_768,
    PNG_768_2x,
    WEBP_Mob,
    WEBP_Mob_2x,
    PNG_Mob,
    PNG_Mob_2x
  },

  LIST: {
    title: "Order a customized product research report based on your criteria",
    items: [
      {
        template: (
          <p>
            Decide how many product ideas you want to receive
          </p>
        )
      },
      {
        template: (
          <p>
            Place your order on the <TrackedLink
            category="SalesEstimatorLP"
            action="SellerhookSite"
            target
            path="https://sellerhook.com/?utm_source=site&utm_medium=amzscout&utm_campaign=sales-estimator-text"
          >
            Sellerhook site
          </TrackedLink>
          </p>
        )
      },
      {
        template: (
          <p>
            Complete the survey to provide any product criteria that are important to you
          </p>
        )
      },
      {
        template: (
          <p>
            Give our experienced specialists time to perform your product research. During the research process, they
            will carefully consider your specific criteria and evaluate other relevant data as well.
          </p>
        )
      },
      {
        template: (
          <p>
            Get product ideas selected especially for you within 10 business days
          </p>
        )
      }
    ]
  }
};

export default TRENDS_CONTENT;
