import React from "react";
import "./index.scss";
import Container from "../../common/Container";

const Intro = () => {
  return (
    <section className="PgSE-Intro">
      <Container modeClass="PgSE-Intro__container">
        <h1 className="PgSE-Intro__h1">Amazon Sales Estimator</h1>
        <p className="PgSE-Intro__description">
          Estimate Amazon Sales Quickly and Easily: The Entire Process Explained
        </p>
      </Container>
    </section>
  );
};

export default Intro;
