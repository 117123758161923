import React from "react"
import Pic1 from "../../../assets/images/raster/components/Reviews/jonh.jpg"
import Pic2 from "../../../assets/images/raster/components/Reviews/mario.jpg"

const PG_SE_REVIEWS_CONTENT = {
  reviews: [
    {
      pic: Pic1,
      name: "John",
      text: "I used other tools before I switched to AMZScout. I really like it, especially the keyword trackers and product trackers that give me a good reference.",
      href: "/blog/from-one-game-to-another-john's-story-of-success-on-amazon/",
    },
    {
      pic: Pic2,
      name: "Mario",
      text: "Honestly, it is like you are trying to find the exit in the dark, and suddenly get a flashlight. With AMZScout, quality and productivity go hand in hand. I use it mostly for looking up products and of course to track competitors.",
      href: "/blog/who-wants-to-be-a-millionaire-marios-amazon-success-story/",
    },
  ],
}

export default PG_SE_REVIEWS_CONTENT
