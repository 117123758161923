import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import Close from "./Close";
import "./index.scss";

const DealsBundle = (props) => {
  const { isShowDeals, hideCloseButton, clickOpenHandler, isBottomLine } =
    props;
  if (!isShowDeals) {
    return null;
  }
  return (
    <div
      className={getTrueClasses(
        "PgSE-DSE-section",
        "PgSE-DSE-section_fixed",
        isBottomLine && "PgSE-DSE-section_stay"
      )}
    >
      <div className="PgSE-DSE-container">
        <div className="PgSE-DSE-content" onClick={clickOpenHandler}>
          <div className="PgSE-DSE__title">
            <p>
              DISCOVER READY-TO-LAUNCH PRODUCTS FROM EXPERIENCED RESEARCHERS
            </p>
            <p>
              Get ideas for unique products with high sales, and massive revenue
              potential for your business
            </p>
          </div>
          <button className="PgSE-DSE__cta elem__btn elem__btn_green">
            SEE DETAILS
          </button>
          <Close hideCloseButton={hideCloseButton} />
        </div>
      </div>
    </div>
  );
};
DealsBundle.propTypes = {
  isShowDeals: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  clickOpenHandler: PropTypes.func,
};
export default DealsBundle;
