const WORK_CONTENT = [
  {
    mod: "one",
    title: "Open a Product Page on Amazon",
    text: [
      "Go to Amazon.com and search for the type of item you’re looking for.",
      "Sort through the search results and select a product you’re interested in."
    ]
  },
  {
    mod: "two",
    title: "Find a Product’s Sales Rank",
    text: "Scroll down the product listing until you find the best seller rank (or “BSR”). Copy this number down."
  },
  {
    mod: "three",
    title: "Get a Monthly Sales Estimate",
    text: "Go to our Amazon Sales Estimator and enter the country, category, and BSR for your item, then click “Calculate Sales”"
  }
];

export default WORK_CONTENT;
