import EstimatorModel from "../../models/sales-estimator/estimatorModel";

class EstimatorController extends EstimatorModel {
  constructor(domain, rank, categoryId) {
    super();
    this.domain = domain;
    this.rank = rank;
    this.categoryId = categoryId;
  }
  estimate() {
    return this.request()
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error("Response Error");
        }
      })
      .then((data) => {
        if (data) {
          return data.sales;
        } else {
          throw new Error("Parsing Error");
        }
      });
  }
}

export default EstimatorController;
