import React from "react";
import Block from "../Block";
import { useSelector } from "react-redux";

const StepThree = ({ setRank, handleKeyUp }) => {
  const { rank } = useSelector((state) => state.estimator);
  return (
    <Block stepTitle="Step 3:" labelTitle="Enter Sales Rank">
      <input
        type="number"
        className="PgSE-Estimator__input"
        placeholder="Enter Value"
        value={rank}
        min={0}
        max={999999999}
        onChange={(e) => setRank(e.target.value)}
        onKeyUp={handleKeyUp}
        onInput={(e) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, "");
        }}
      />
    </Block>
  );
};

export default StepThree;
